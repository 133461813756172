<template>
  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px" min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
      ? `border-left: `
      : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div class="d-flex align-end mt-4 headText">
          <h2 class="headTextProject" :style="fontSize">CookBook</h2>
          <v-spacer></v-spacer>
          <v-btn small text @click="$router.go(-1)" class="orange--text" style="border: 2px solid orange">Back</v-btn>
        </div>
      </div>

      <div class="font-weight-light black--text caption mb-2">
        <p class="desc">(responsive design)</p>
      </div>

      <v-row>
        <v-col class=".main-text">
          <br />
          <span class="textInsideProject"> Language: </span>
          JavaScript<br />
          <span class="textInsideProject"> Framework: </span>
          Vue + Vuex (State management pattern + library)<br />
          <span class="textInsideProject">UI Library:</span> Vuetify
          <br />
          <span class="textInsideProject">Database:</span> SQL (MySql as RDBMS)
          <br />
          <span class="textInsideProject">Server:</span>
          Mars (www.mars-server.net,using JavaScript as Back-End language,
          custom authentication, storage) <br />
          <span class="textInsideProject">devDependencies: </span>cli, babel,
          eslint, router, vuex, sass, vuetify
          <br />
          <span class="textInsideProject">Short info:</span>
          CookBook is a Single-Page application that allows users to display and
          share their own recipes. To ensure accuracy, users are required to
          input the specific ingredients for each recipe. This eliminates any
          potential issues with variations in ingredient names. Users can simply
          'check' the ingredients they include in their recipe for easy
          organization and reference.

          <div class="d-flex justify-center git-gallery-live">
            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange" @click="openGallery(0)">
              <i class="orange--text fas fa-bullseye pa-0"> Gallery</i>
            </v-btn>
          </div>

          <br />
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Gallery -->
    <!-- <v-row class="ma-0">
      <v-col class="px-4">
        <h2 class="">Gallery</h2>
        <v-container class="mt-5" grid-list-md>
          <v-layout row wrap>
            <v-flex xs6 sm4 lg2 v-for="(image, i) in images" :key="i" class="d-flex justify-center">
              <img v-lazy="image.src" :style="picSize" style="cursor: pointer" @click="openGallery(i)" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row> -->

    <!-- Import component LightBox za prikaz galerije sa kontrolama levo i desno-->
    <LightBox ref="lightbox" :media="images" :show-caption="true" :show-light-box="false" :closable="true" />
  </v-card>
</template>

<script>
  import LightBox from "vue-image-lightbox";
  require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

  export default {
    components: { LightBox },
    data() {
      return {
        images: [
          {
            thumb: require("@/assets/projects/CookBook/slika-2.png"),
            src: require("@/assets/projects/CookBook/slika-2.png"),
            caption: "<h4 class='pt-2'>Main look with open menu</h4>",
          },
          {
            thumb: require("@/assets/projects/CookBook/slika-3.png"),
            src: require("@/assets/projects/CookBook/slika-3.png"),
            caption:
              "<h4 class='pt-2'>User info (back), change password (front)</h4>",
          },
          {
            thumb: require("@/assets/projects/CookBook/slika-4.png"),
            src: require("@/assets/projects/CookBook/slika-4.png"),
            caption: "<h4 class='pt-2'>Spices and display per page</h4>",
          },
          {
            thumb: require("@/assets/projects/CookBook/slika-5.png"),
            src: require("@/assets/projects/CookBook/slika-5.png"),
            caption: "<h4 class='pt-2'>Spices - new, search, sort</h4>",
          },
          {
            thumb: require("@/assets/projects/CookBook/slika-6.png"),
            src: require("@/assets/projects/CookBook/slika-6.png"),
            caption: "<h4 class='pt-2'>Checked and not checked spices</h4>",
          },
          {
            thumb: require("@/assets/projects/CookBook/slika-7.png"),
            src: require("@/assets/projects/CookBook/slika-7.png"),
            caption:
              "<h4 class='pt-2'>Confirmation whether you want to delete</h4>",
          },
          {
            thumb: require("@/assets/projects/CookBook/slika-8.png"),
            src: require("@/assets/projects/CookBook/slika-8.png"),
            caption: "<h4 class='pt-2'>Intake of new spice</h4>",
          },
        ],
      };
    },
    computed: {
      fontSize() {
        switch (this.$vuetify.breakpoint.name) {
          // case "xs":
          // case "sm":
          //   return { "font-size": "21px !important" };
          default:
            return {
              "font-size": "1.3em",
              "letter-spacing": "0.05em",
              "text-transform": "uppercase",
            };
        }
      },
      picSize() {
        switch (this.$vuetify.breakpoint.name) {
          case "lg":
            return { width: "95%", height: "80px" };
          default:
            //tj. xs, sm, md
            return { width: "auto", height: "96px", overflow: "hidden" };
        }
      },
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
      },
    },
  };
</script>

<style></style>
